import *  as joint from '../rappid/rappid.min.js'

export const layoutGraph = (graph, layoutParam) => {
  const options = {
    nodeSep: Number(layoutParam.nodeSep),
    edgeSep: Number(layoutParam.edgeSep),
    rankSep: Number(layoutParam.rankSep),
    marginX: Number(layoutParam.marginX), 
    marginY: Number(layoutParam.marginY),
    resizeClusters: layoutParam.resizeClusters,
    rankDir: layoutParam.rankDir,
    ranker: layoutParam.ranker 
  }
  joint.layout.DirectedGraph.layout(graph, options);
}