import { contextGetParam } from './context.js'
import { jointGetShape } from './joint-shape.js'
import { paramInteractiveConvert, objectConvert, booleanConvert } from './param-convert'
import { paramModelGetElement, paramModelGetPossibleOutcommingModelLinks } from './param-model'
import { paramShapesGetShapeByName } from './param-shapes'
import { startLinking, doLinking, stopLinking } from './linking'
import { paramGetModel } from './param.js'



export const haloRender = (context, cellView) => {
  const element = cellView.model
  const param = contextGetParam(context)
  const shapeType = element.get('type')
  const shapeModel = context.shapeTypeModelMap[shapeType]
  if (!shapeModel) {
    return
  }
  const shapeModelName = shapeModel.name
  const model = paramGetModel(param)
  const halo = new joint.ui.Halo({
    cellView,
    boxContent: false
  })
  halo.removeHandle('remove')
  halo.removeHandle('clone')
  halo.removeHandle('fork')
  halo.removeHandle('resize')
  halo.removeHandle('rotate')
  halo.removeHandle('unlink')
  halo.removeHandle('link')
  let universalLink = false
  const possibleLinks = paramModelGetPossibleOutcommingModelLinks(model, shapeModelName)
  possibleLinks.forEach(possibleLink => {
    let allow = true
    if (!possibleLink.allowMultiple) {
      const outboundLinks = context.graph.getConnectedLinks(element, {outbound: true})
      allow = outboundLinks.every(outboundLink => {
        const outboundLinkName = outboundLink.get('name')
        return outboundLinkName !== possibleLink.name
      })
/*      if (allow) {
        const embeddedLink = element.get('embeddedLink')
        if (embeddedLink) {
          const shapeModelName = context.shapeTypeModelMap[embeddedLink.type].name
          allow = shapeModelName !== possibleLink.name
        }
      }*/
    }
    if (allow) {
      if (possibleLink.halo) {
        const linkHalo = possibleLink.halo
        const shapeDefinition = paramShapesGetShapeByName(context.paramShapes, possibleLink.name)
        const shape = jointGetShape(shapeDefinition.type)
        halo.addHandle({
          name: linkHalo.name,
          position: linkHalo.position,
          events: {
            pointerdown: (event, x, y) => startLinking(context, shape, cellView, event, x, y),
            pointermove: (event, x, y) => doLinking(context, event, x, y),
            pointerup: (event, x, y) => stopLinking(context, event, x, y)
          }
        })
      } else {
        universalLink = true
      }
    }
  })
  if (universalLink) {
    halo.addHandle({
      name: 'link',
      position: 's',
      events: {
        pointerdown: (event, x, y) => startLinking(context, null, cellView, event, x, y),
        pointermove: (event, x, y) => doLinking(context, event, x, y),
        pointerup: (event, x, y) => stopLinking(context, event, x, y)
      }
    })
  } 
  context.halo = {
    view: halo,
    cellView
  }
  halo.render()
}

export const haloClear = context => {
  joint.ui.Halo.clear(context.paper)
  delete context.halo
}

export const isEventInHalo = (context, event) => {
  if (!context.halo) {
    return false
  }
  const bbox = context.halo.cellView.getBBox()
  bbox.moveAndExpand({
     x: -40,
     y: -40,
     width: 80,
     height: 80
  })
  const offset = context.paper.$el.offset()
  const point = {
     x: event.pageX - offset.left,
     y: event.pageY - offset.top
  }
  return bbox.containsPoint(point)
}