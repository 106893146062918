import { paramModelGetPossibleOutcommingModelLinks } from './param-model'
import { paramShapesGetShapeByName } from './param-shapes'
import { jointGetShape } from './joint-shape'
import { objectConvert, booleanConvert } from './param-convert'
import { resolveEmbedding } from './embedding'
import { displayCellContextMenu } from './cell-context-menu'
import { haloClear, haloRender, isEventInHalo } from './halo'
import * as joint from '../rappid/rappid.min.js'
import {V} from '../rappid/rappid.min.js'
import { contextGetParam } from './context'
import { isDefined } from './library'
import {bindAdjustVerticesInteractionEvents, perpendicularLinks} from './adjust-vertices'
import {selectCell} from './select-cell'
import { createConnectionValidator } from './linking'
import { paramGetPaper } from './param'
import { registerLinkToolsHandlers } from './link-tools'
import { paramPaperGetDrawGrid, paramPaperGetGridSize } from './param-paper'

const toFront = (graph, element) => {
  element.toFront()
  graph.getConnectedLinks(element).forEach(link => link.toFront())
  element.getEmbeddedCells().forEach(cell => {
    if (cell.isElement()) {
      toFront(graph, cell)
    }
  })
}

const selectElement = (elementView, context) => {
  const paper = context.paper
  unselectElement();
  paper.selectedElement = elementView.model;
}

const unselectElement = (context) => {
  const paper = context.paper
   if (paper.highlighter) {
    paper.highlighter.remove();
    paper.highlighter = null;
  } 
  if (paper.textarea) {
    paper.textarea.blur();
  }
  delete paper.selectedElement;
}

export const registerPaperHandlers = context => {
  const paper = context.paper
  const graph = context.graph
  const param = contextGetParam(context)
  const paramPaper = objectConvert(param.paper)

  paper.on('cell:pointerdown', (cellView, event, x, y) => {
    unselectElement(context);
    // Remove after complete removal of editor - links
    joint.ui.TextEditor.close()
  })

  paper.on('element:pointerdown', (elementView, _event, _x, _y) => {
    // have to be async - otherwise pointerdblclick on element with link is not working
    setTimeout(() => toFront(graph, elementView.model), 100) 
  })

  paper.on('element:pointerup', (elementView, _event, _x, _y) => {
    resolveEmbedding(context, elementView.model)
  })
  
  paper.on('cell:pointerup', cellView => {
    const cell = cellView.model
    if (!cell.get('temporary')) {
      selectCell(context, cellView)
    }
  })

    
  paper.on('blank:pointerdown', (event, x, y) => {
    unselectElement(context);
    // Remove after complete removal of editor - links
    joint.ui.TextEditor.close()
    context.graph.getCells().forEach(cell => cell.set('selected', false))
    if (event.shiftKey) {
      context.selection.startSelecting(event, x, y)
    } else {
      context.selection.collection.reset([])
      context.paperScroller.startPanning(event, x, y)
    }
  })
  
  const createEditor = (elementView, textSelector, textProperty, context) => {
    if (isDefined(textSelector)) {
      const paper = context.paper
        const { model: editedElement } = elementView
        unselectElement(context);
        
        //Targeting exact svg element would be better, precise coordinates must be calculated using element.getAbsolutePointFromRelative function
        //const bbox = elementView.findBySelector(textSelector)[0].getBBox();
        const bbox = editedElement.getBBox();
        paper.textarea = document.createElement('textarea');
        const textarea = paper.textarea;

        // Position, Size & Styling
        textarea.style.position = 'absolute';
        textarea.style.boxSizing = 'border-box';
        textarea.style.transformOrigin = '0 0';
        textarea.style.alignContent = 'center';
        textarea.style.textAlign = 'center';
        textarea.style.letterSpacing = '0';
        textarea.style.resize = 'none';
        textarea.style.lineHeight = editedElement.attr(textSelector + '/line-height');
        textarea.className = "editor";

        // Content
        textarea.style.padding = `${2}px ${2}px`;
        textarea.style.transform = V.matrixToTransformString(paper.matrix().translate(bbox.x, bbox.y));
        textarea.style.width = `${bbox.width}px`;
        textarea.style.height = `${bbox.height}px`;
        textarea.style.fontSize = editedElement.attr(textSelector + '/font-size') + 'px';
        textarea.style.fontFamily = editedElement.attr(textSelector + '/font-family');
        textarea.style.fontWeight = editedElement.attr(textSelector + '/font-weight');
        
        textarea.value = editedElement.attr(textProperty);

        // Append the this.textarea to the paper
        paper.el.appendChild(textarea);
        textarea.focus();

        // Select all text
        textarea.setSelectionRange(0, textarea.value.length);

        // Attach Event Listeners
        textarea.addEventListener('blur', () => {
            editedElement.startBatch('text');
            editedElement.attr(textProperty, textarea.value);
            editedElement.stopBatch('text');
            textarea.remove();
        });

        textarea.addEventListener('keyup', (evt) => {
            if (evt.key === 'Enter' && (!evt.shiftKey || textarea.wrap === 'off')) {
                const index = textarea.selectionEnd;
                textarea.value = textarea.value.slice(0, index - 1) + textarea.value.slice(index);
                textarea.blur();
                selectElement(elementView, context);
            }
            if (evt.key === 'Escape') {
                textarea.value = editedElement.attr(textProperty);
                textarea.blur();
                selectElement(elementView, context);
            }
        });


      //joint.ui.TextEditor alternative does not work with spaces and/or wrap the text correctly
      /* 
      joint.ui.TextEditor.edit(elementView.selectors[textSelector], {
        cellView: elementView,
        textProperty: 'attrs/' + textProperty
      }) */
    }
  }

  paper.on('element:pointerdblclick', elementView => {
    const element = elementView.model
    const textSelector = element.get('textSelector')
    const textProperty = element.get('textProperty') || textSelector + '/text'

    createEditor(elementView, textSelector, textProperty, context)
  })

  paper.on('link:pointerdblclick', (linkView, event) => {
    joint.ui.TextEditor.close()
    unselectElement(context);
    const link = linkView.model
    const labels = link.labels()
    if (labels.length === 0) {
      /*callWithoutChange(context, () => {
        link.appendLabel({
          attrs: {
              text: {
                  text: ' '
              }
          }
        })
      })*/
      return
    }
    const index = Number(linkView.findAttribute('label-idx', event.target));
    const textEl = linkView.el.querySelector('.labels text')
    const textProperty = ['labels', index, 'attrs', 'text', 'text'];

    //createEditor(linkView, 'labels', textProperty, context)
    //joint.ui.TextEditor alternative does not work with spaces and/or wrap the text correctly
    joint.ui.TextEditor.edit(textEl || event.target, {
        cellView: linkView,
        textProperty: ['labels', index, 'attrs', 'text', 'text'],
        annotationsProperty: ['labels', index, 'attrs', 'text', 'annotations']
    }); 
  });
  
  paper.on('cell:contextmenu', (cellView, event, x, y) => 
    displayCellContextMenu(context, cellView, event, x, y))

  paper.on('cell:mouseover', (cellView, event) => {
    const cell = cellView.model
    if (event.buttons == 0 && !cell.isLink()) {
      if (context.halo) {
        const parent = context.halo.cellView.model.getParentCell()
        if (parent && parent === cellView.model) {
          return
        }
      }
      haloRender(context, cellView)
      /*
      if (mm.haloCellView != cellView && !mm.haloBlocked) {
        if (!isCellSelected(mm, cell))
      
      }*/
    }
  })

  paper.$el.mousemove(event => {
    if (event.buttons == 0 && !context.haloBlocked) {
      if (!isEventInHalo(context, event)) {
        haloClear(context)
      }
    }
  })

  registerLinkToolsHandlers(context)


  if (booleanConvert(paramPaper.adjustLinkVertices, false)) {
    bindAdjustVerticesInteractionEvents(context)
  }
  
}


export const createPaper = context => {
  const param = contextGetParam(context)
  const paramPaper = paramGetPaper(param)
  const graph = context.graph
  const paperConfig = {
    model: graph,
    width: Number(paramPaper.width || 800),
    height: Number(paramPaper.height || 600),
    drawGrid: paramPaperGetDrawGrid(paramPaper.grid),
    gridSize: paramPaperGetGridSize(paramPaper.grid),

    interactive: {
      vertexAdd: false
      //vertexRemove: false
    },
    defaultLink: (elementView, magnet) => {
      const element = elementView.model
      const elementName = element.get('name')
      const port = magnet.className.baseVal.split(' ')[1]
      const model = param.model
      const links = paramModelGetPossibleOutcommingModelLinks(model, elementName, port)
      const link = links[0]
      const shapeDefinition = paramShapesGetShapeByName(context.paramShapes, link.name)
      const shape = jointGetShape(shapeDefinition.type)
      return new shape
    },
    validateConnection: createConnectionValidator(context),
    validateMagnet: (elementView, magnet, _evt) => {
      const element = elementView.model
      const elementName = element.get('name')
      const port = magnet.className.baseVal.split(' ')[1]
      const model = param.model
      const links = paramModelGetPossibleOutcommingModelLinks(model, elementName, port)
      if (links.length !== 1) {
        return false
      }
      const link = links[0]
      if (link.allowMultiple) {
        return true
      }
      const outboundLinks = graph.getConnectedLinks(element, {outbound: true})
      return outboundLinks.every(outboundLink => outboundLink.get('name') !== link.name)
    }
  }
  if (paramPaper.defaultRouter) {
    paperConfig.defaultRouter = paramPaper.defaultRouter
  }
  if (paramPaper.defaultConnector) {
    paperConfig.defaultConnector = paramPaper.defaultConnector
  }
  if (paramPaper.perpendicularLinks) {
    paperConfig.defaultAnchor = perpendicularLinks;
  }
  if (paramPaper.defaultConnectionPoint) {
    paperConfig.defaultConnectionPoint = {name: paramPaper.defaultConnectionPoint};
  }

  const paper = new joint.dia.Paper(paperConfig)
  return paper
}

export const initializePaper = context => {
  context.paper = createPaper(context)
  registerPaperHandlers(context)
}

export const initializeSnaplines = context => {
  context.snaplines = new joint.ui.Snaplines({ paper: context.paper })
  context.snaplines.startListening()
}

export const initializeSelection = context => {
  context.elementCollection = new Backbone.Collection
  context.selection = new joint.ui.Selection({
      paper: context.paper,
      collection: context.elementCollection,
  })

  context.selection.removeHandle('rotate')
  context.selection.removeHandle('move')
  context.selection.on('selection-box:pointerdown', function(elementView, evt) {
    if (evt.ctrlKey || evt.metaKey) {
      context.selection.collection.remove(elementView.model)
    }
  })
}