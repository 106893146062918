import * as joint from '../rappid/rappid.min.js'
import {paramLayoutConver, attrsConvert, arrayConvert} from './param-convert'
import {jointGetShape, jointDefineElementShapes} from './joint-shape'
import {consume} from './connect'
import {equals, clone} from './library'


const paramElementJointElementCreate = paramElement => {
  const shape = jointGetShape(paramElement.type)
  const elementProps = {}
  if (paramElement.dragEnd) {
    elementProps.dragEnd = paramElement.dragEnd
  }
  if (paramElement.size) {
    const paramSize = paramElement.size
    elementProps.size = {
      width: Number(paramSize.width), 
      height: Number(paramSize.height)
    }
  }
  if (paramElement.attrs) {
    elementProps.attrs = attrsConvert(paramElement.attrs)
  }
  const element = new shape(elementProps)
  return element
}

class Stencil extends React.Component {
  constructor(props) {
    super(props)
    this.stencilRef = React.createRef()
  }
  componentDidMount() {
    const self = this
    const props = self.props
    const param = props.data.param
    consume('diagram', context => {
      const shapes = arrayConvert(param.shape)
      jointDefineElementShapes(shapes)
      self.diaContext = context
      self.createStencil()
    })
    this.stencilContext = {elements: param.element}
  }
  createStencil() {
    const paper = this.diaContext.paperScroller
    const param = this.props.data.param
    const paramGroups = arrayConvert(param.group)
    const paramElements = arrayConvert(param.element)
    const layout = paramLayoutConver(param.layout)
    const stencilConfig = {
      paper: paper,
      height: 800,
      // width: 100,
      search: {
      '*': ['dragEnd/attrs/label/textWrap/text', 'attrs/name/text'],
      },
      layout,
      dragEndClone: element => {
        const dragEnd = element.get('dragEnd')
        const elementProps = {}
        let type = element.get('type')
        if (dragEnd) {
          if (dragEnd.attrs) {
            elementProps.attrs = dragEnd.attrs
          } 
          if (dragEnd.user) {
            elementProps.user = dragEnd.user 
          }
          type = dragEnd.type
        }
        
        const shape = jointGetShape(type)
        return new shape(elementProps)
      } 
    }

    let elements
    if (paramGroups.length > 0) {
      const groupsElements = {}
      paramGroups.forEach(group => {
        const groupName = group.name
        const paramGroupElements = paramElements.filter(element => element.group === groupName)
        const groupElements = paramGroupElements.map(paramElementJointElementCreate)
        groupsElements[groupName] = groupElements
      })
      const groupsObject = {}
      paramGroups.forEach(group => {
        if (arrayConvert(groupsElements[group.name]).length > 0) {
          groupsObject[group.name] = {
            label: group.label,
            index: Number(group.index),
            closed: group.closed
          }
        }
      })
      stencilConfig.groups = groupsObject
      elements = groupsElements
      
    } else {
      elements = paramElements.map(paramElementJointElementCreate)
    }
    this.stencil = new joint.ui.Stencil(stencilConfig)
    this.stencilRef.current.appendChild(this.stencil.render().el)
    // No Matches Found
    this.stencil.el.dataset.textNoMatchesFound = 'Nic nenalezeno!';
    // Search Placeholder
    this.stencil.el.querySelector('.search').placeholder = 'Hledat ...';
    this.stencil.load(elements)
  }
  componentDidUpdate() {
    const props = this.props
    const param = props.data.param
    if (!equals(param.element, this.stencilContext.elements)) {
      this.stencilContext.elements = clone(param.element)
      this.stencil.remove()
      this.createStencil()
    }
  }
  render() {
/*     const style= {
      height: '800px'
    } */
    return <div ref={this.stencilRef} class='joint-stencil-holder'/>
  }
}

MC.registerReactRomponent('stencil', Stencil)