
import {contextUpdateSelectedElements} from './select-cell'
import {contextUpdateGraph} from './update-graph'
import {equals} from './library'
import {jointDefineShapes} from './joint-shape'
import {produce, unproduce} from './connect'
import {clone} from './library'
import { initializeSnaplines, initializeSelection, initializePaper} from './paper'
import {initializeGraph, graphLoad, graphLayout} from './graph'
import {initializeValidator} from './validator'
import {initializeCommandManager} from './command-manager'
import {initializePaperScroller} from './paper-scroller'
import './diagram.css'
import { objectConvert, numberConvert } from './param-convert.js'
import { initializeKeyboard } from './keyboard'
import { paramGetModel } from './param'
import { paramModelGetShapes } from './param-model'
import {createShapeTypeModelMap, createShapeMap} from './data-maps'
import { paramPaperGetDrawGrid, paramPaperGetGridSize } from './param-paper'
import { handlePaperExport } from './export.js'

class Diagram extends React.Component {
  constructor(props) {
    super(props)
    this.flow = props.data.flow
    this.rbsid = props.data.rbsid
    this.diagramRef = React.createRef()
  }
  componentWillUnmount() {
    unproduce('diagram')
    window.removeEventListener("message", this.onMessage)
  }
  componentDidMount() {
    const props = this.props
    const param = props.data.param
    const paramModel = paramGetModel(param)
    const paramShapes = paramModelGetShapes(paramModel)
    const shapeTypeModelMap = createShapeTypeModelMap(paramModel)
    const shapeMap = createShapeMap(paramModel)
    const context = {props, diagram: this, displayedParamGraph: clone(param.diagram), withoutChangesLevel: 0, paramShapes, shapeTypeModelMap, shapeMap}
    this.diaContext = context
    jointDefineShapes(objectConvert(context.paramShapes))
    initializeGraph(context)
    initializePaper(context)
    initializePaperScroller(context, this.diagramRef.current)
    graphLoad(context)
    graphLayout(context)
    initializeCommandManager(context)
    initializeValidator(context)
    initializeSnaplines(context)
    initializeSelection(context)
    initializeKeyboard(context)
    contextUpdateSelectedElements(this.diaContext)
    produce("diagram", context)
  }
  componentDidUpdate() {
    const self = this
    const props = self.props
    const context = this.diaContext
    const param = props.data.param
    context.props = props
    const paramPaper = objectConvert(param.paper)
    if (!equals(param.graph, context.displayedParamGraph)) {
      contextUpdateGraph(context, param.graph, context.displayedParamGraph)
      contextUpdateSelectedElements(context)
    }
    if (!equals(paramPaper.zoom, context.zoom)) {
      context.paperScroller.zoom(numberConvert(paramPaper.zoom, 1), {absolute: true})
      context.zoom = paramPaper.zoom
    }

    if (paramPaper.exportTo)
      handlePaperExport(context)

    context.paper.setGridSize(paramPaperGetGridSize(paramPaper.grid))

    if (paramPaperGetDrawGrid(paramPaper.grid))
      context.paper.drawGrid()
    else
      context.paper.clearGrid()

  }
  render() {
/*     const style = {
      height: this.props.data.param.height || '600px'
    }
 */
    return <div ref={this.diagramRef} class="paper-scroller"><div className="paper"/></div>
  }
}

MC.registerReactRomponent('diagram', Diagram)
