import {arrayConvert, attrsConvert, booleanConvert, objectConvert} from './param-convert'
import {paramSizeConvert} from './param-graph'
import {isDefined} from './library'

export const paramShapesGetLinks = shapes => {
  return arrayConvert(objectConvert(shapes).link)
}

export const paramShapesGetModelCellsShapes = (shapes, cells) => {
  const cellNames = cells.map(cell => cell.name)
  return paramShapesGetShapeArray(shapes).filter(shape => {
    return cellNames.find(name => name === shape.name)
  })
}

export const paramShapesGetShapeArray = shapes => {
  shapes = objectConvert(shapes)
  return arrayConvert(shapes.link).concat(arrayConvert(shapes.element))
}

export const paramShapesGetCellShape = (shapes, cell) => {
  return paramShapesGetShape(shapes, cell.get('type'))
}

export const paramShapesGetShape = (shapes, type) => {
  return paramShapesGetShapeArray(shapes).find(shape => shape.type === type)
}
export const paramShapesGetShapesByName = (shapes, name) => paramShapesGetShapeArray(shapes).filter(shape => shape.name === name)

export const paramShapesGetShapeByName = (shapes, name) => paramShapesGetShapesByName(shapes, name)[0]

export const paramShapesGetShapeName = (shapes, type) => {
  const shape = paramShapesGetShape(shapes, type)
  if (!shape) {
    MC.error('Shape for type "' + type + '" is not defined in model.')
  }
  return shape.name
}

const paramShapePortGroupConvert = paramPortGroup => {
  const group = {...paramPortGroup,
  attrs: attrsConvert(paramPortGroup.attrs)}
  delete group.id
  group.markup = group.markupString
  delete group.markupString
  return group
}

export const paramMarkupObjectConvert = (paramMarkupElement, selectors) => {
  const markupObject = {...paramMarkupElement}
  const selector = paramMarkupElement.selector 
  if (selector) {
    if (selectors.indexOf(selector) !== -1) {
      throw new Error('Selector ' + selector + ' is not unique.')
    }
    selectors.push(selector)  
  }
  if (markupObject.child) {
    markupObject.children = paramMarkupConvert(markupObject.child)
    delete markupObject.child
  }
  return markupObject
}

export const paramMarkupConvert = paramMarkup => {
  const selectors = []
  return arrayConvert(paramMarkup).map(paramMarkupObject => paramMarkupObjectConvert(paramMarkupObject, selectors))
}


export const paramShapeConvert = paramShape => {
  const shape = {...paramShape,
    attrs: attrsConvert(paramShape.attrs)
  }
  if (paramShape.markup) {
    try {
      shape.markup = paramMarkupConvert(paramShape.markup)    
    } catch (error) {
      throw new Error('Error in shape ' + paramShape.type + ': ' + error.message)
    }

  }
  if (paramShape.markupString) {
    shape.markup = paramShape.markupString
    delete paramShape.markupString
  }
  if (paramShape.ports) {
    const paramPorts = paramShape.ports 
    const groups = {}
    arrayConvert(paramPorts.group).forEach(group => {
      groups[group.id] = paramShapePortGroupConvert(group)
    })
    shape.ports = {
      groups,
      items: arrayConvert(paramPorts.item)
    }
  }
  if (paramShape.size) {
    shape.size = paramSizeConvert(paramShape.size)
  }
  if (isDefined(paramShape.minSize)) {
    shape.minSize = paramSizeConvert(paramShape.minSize)
  }
  if (isDefined(paramShape.preserveAspectRatio)) {
    shape.preserveAspectRatio = booleanConvert(paramShape.preserveAspectRatio, false)
  }
  if (isDefined(paramShape.textSelector)) {
    shape.textSelector = paramShape.textSelector
  }
  if (isDefined(paramShape.textProperty)) {
    shape.textProperty = paramShape.textProperty
  }  
  if (isDefined(paramShape.allowRotation)) {
    shape.allowRotation = booleanConvert(paramShape.allowRotation, false)
  }
  if (isDefined(paramShape.smooth)) {
    shape.smooth = booleanConvert(paramShape.smooth, false)
  }
  return shape
}

export const paramShapesConvert = shapes => {
  return arrayConvert(shapes).map(paramShapeConvert)
}

export const paramShapeGetAllowResize = (paramShape) => {
  return booleanConvert(objectConvert(paramShape).allowResize, false) 
}

export const paramShapeGetAllowEmbedding = (paramShape) => {
  return booleanConvert(objectConvert(paramShape).allowEmbedding, false) 
}

export const paramShapeGetShowLinkTools = paramShape => booleanConvert(paramShape.showLinkTools, false)