import * as _ from 'underscore'
import * as joint from '../rappid/rappid.min.js'
import { callAsBatch } from './context.js';
import { g } from '../rappid/rappid.min.js'

export function perpendicularLinks(view, magnet, ref, opt) {
    var refRect;
    if (ref instanceof Element) {
        var refView = this.paper.findView(ref);
        if (refView) {
            if (refView.isNodeConnection(ref)) {
                var distance = ('fixedAt' in opt) ? opt.fixedAt : '50%';
                refRect = new g.Rect(getPointAtLink(refView, distance));
            } else {
                refRect = refView.getNodeBBox(ref);
            }
        } else {
            // Something went wrong
            refRect = new g.Rect();
        }
    } else {
        refRect = new g.Rect(ref);
    }

    var angle = view.model.angle();
    var bbox = view.getNodeBBox(magnet);
    var anchor = bbox.center();
    var topLeft = bbox.origin();
    var bottomRight = bbox.corner();

    var padding = opt.padding;
    if (!isFinite(padding)) padding = 0;

    if ((topLeft.y + padding) <= (refRect.y + refRect.height) && refRect.y <= (bottomRight.y - padding)) {
        var y = (Math.max(refRect.y, topLeft.y) + Math.min(refRect.y + refRect.height, bottomRight.y)) / 2;
        var dy = (y - anchor.y);
        anchor.x += (angle === 0 || angle === 180) ? 0 : dy * 1 / Math.tan(g.toRad(angle));
        anchor.y += dy;
    } else if ((topLeft.x + padding) <= (refRect.x + refRect.width) && refRect.x <= (bottomRight.x - padding)) {
        var x = (Math.max(refRect.x, topLeft.x) + Math.min(refRect.x + refRect.width, bottomRight.x)) / 2;
        var dx = (x - anchor.x);
        anchor.y += (angle === 90 || angle === 270) ? 0 : dx * Math.tan(g.toRad(angle));
        anchor.x += dx;
    }

    return anchor;
}

// supporting functions for perpendicular links
function getPointAtLink(view, value) {
    var parsedValue = parseFloat(value);
    if (isPercentage(value)) {
        return view.getPointAtRatio(parsedValue / 100);
    } else {
        return view.getPointAtLength(parsedValue);
    }
}

const isString = function (value) {
    var toString = Object.prototype.toString;
    return typeof value === 'string' || (!!value && typeof value === 'object' && toString.call(value) === '[object String]');
};

const isPercentage = function (val) {
    return isString(val) && val.slice(-1) === '%';
};

function adjustVertices(graph, cell) {

    // if `cell` is a view, find its model
    cell = cell.model || cell;

    if (cell instanceof joint.dia.Element) {
        // `cell` is an element

        _.chain(graph.getConnectedLinks(cell))
            .groupBy(function (link) {

                // the key of the group is the model id of the link's source or target
                // cell id is omitted
                return _.omit([link.source().id, link.target().id], cell.id)[0];
            })
            .each(function (group, key) {

                // if the member of the group has both source and target model
                // then adjust vertices
                if (key !== 'undefined') adjustVertices(graph, _.first(group));
            })
            .value();

        return;
    }

    // `cell` is a link
    // get its source and target model IDs
    var sourceId = cell.get('source').id || cell.previous('source').id;
    var targetId = cell.get('target').id || cell.previous('target').id;

    // if one of the ends is not a model
    // (if the link is pinned to paper at a point)
    // the link is interpreted as having no siblings
    if (!sourceId || !targetId) {
        // no vertices needed
        cell.unset('vertices');
        return;
    }

    // identify link siblings
    var siblings = graph.getLinks().filter(function (sibling) {

        var siblingSourceId = sibling.source().id;
        var siblingTargetId = sibling.target().id;

        // if source and target are the same
        // or if source and target are reversed
        return ((siblingSourceId === sourceId) && (siblingTargetId === targetId))
            || ((siblingSourceId === targetId) && (siblingTargetId === sourceId));
    });

    var numSiblings = siblings.length;
    switch (numSiblings) {

        case 0: {
            // the link has no siblings
            break;
        }
        default: {

            if (numSiblings === 1) {
                // there is only one link
                // no vertices needed
                cell.unset('vertices');
            }

            // there are multiple siblings
            // we need to create vertices

            // find the middle point of the link
            var sourceCenter = graph.getCell(sourceId).getBBox().center();
            var targetCenter = graph.getCell(targetId).getBBox().center();
            var midPoint = joint.g.Line(sourceCenter, targetCenter).midpoint();

            // find the angle of the link
            var theta = sourceCenter.theta(targetCenter);

            // constant
            // the maximum distance between two sibling links
            var GAP = 20;

            _.each(siblings, function (sibling, index) {

                // we want offset values to be calculated as 0, 20, 20, 40, 40, 60, 60 ...
                var offset = GAP * Math.ceil(index / 2);

                // place the vertices at points which are `offset` pixels perpendicularly away
                // from the first link
                //
                // as index goes up, alternate left and right
                //
                //  ^  odd indices
                //  |
                //  |---->  index 0 sibling - centerline (between source and target centers)
                //  |
                //  v  even indices
                var sign = ((index % 2) ? 1 : -1);

                // to assure symmetry, if there is an even number of siblings
                // shift all vertices leftward perpendicularly away from the centerline
                if ((numSiblings % 2) === 0) {
                    offset -= ((GAP / 2) * sign);
                }

                // make reverse links count the same as non-reverse
                var reverse = ((theta < 180) ? 1 : -1);

                // we found the vertex
                var angle = joint.g.toRad(theta + (sign * reverse * 90));
                var vertex = joint.g.Point.fromPolar(offset, angle, midPoint).toJSON();

                // replace vertices array with `vertex`
                sibling.vertices([vertex]);
            });
        }
    }
}

export const contextAdjustCellVertices = (context, cell) => {
    callAsBatch(context, () => {
        adjustVertices(context.graph, cell)
    })
}

export const bindAdjustVerticesInteractionEvents = context => {

    // bind `graph` to the `adjustVertices` function
    const adjustGraphVertices = cell => contextAdjustCellVertices(context, cell)

    // adjust vertices when a cell is removed or its source/target was changed
    context.graph.on('remove change:source change:target', adjustGraphVertices);

    // adjust vertices when the user stops interacting with an element
    context.paper.on('cell:pointerup', adjustGraphVertices);
}