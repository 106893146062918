import { booleanConvert } from './param-convert.js'

function raiseExportEvent(data) {
    MC.handleEvent(data, 'export')
}

function svgReturn(context, svg) {
  context.props.data.param.export = { svg: svg };
  raiseExportEvent(context.props.data) 
}

function pngReturn(context, png) {
  context.props.data.param.export = { png: png };
  raiseExportEvent(context.props.data) 
}

function jpegReturn(context, jpeg) {
  context.props.data.param.export = { jpeg: jpeg };
  raiseExportEvent(context.props.data) 
}

export function handlePaperExport(context) {
  var paramPaper = context.props.data.param.paper
  const paper = context.paper
  const padding = 1;

  if (booleanConvert(paramPaper.exportTo.toSvg, false)) {
    context.paper.toSVG(svgReturn.bind(null, context), { preserveDimensions: true, area: paper.getContentBBox().inflate(padding) });
  }

  if (booleanConvert(paramPaper.exportTo.toPng, false)) {
    context.paper.toDataURL(pngReturn.bind(null, context), { type: 'image/png', area: paper.getContentBBox().inflate(padding) });
  }

  if (booleanConvert(paramPaper.exportTo.toJpeg, false)) {
    context.paper.toDataURL(jpegReturn.bind(null, context), { type: 'image/jpeg', area: paper.getContentBBox().inflate(padding) });
  }

  delete paramPaper.exportTo
}