import { jointGetShape } from './joint-shape'
import { displayContextMenu } from './context-menu.js'
import { paramModelGetPossibleLinks, paramModelLinkGetShapes, paramModelLinkGetTitle } from './param-model'
import { paramShapesGetCellShape, paramShapeGetAllowEmbedding } from './param-shapes'
import { paramLinkGetEmbeddable, paramLinkGetOutboundEmbeddable, paramLinkGetInboundEmbeddable } from './param-graph'
import { callAsBatch, contextGetParam} from './context'
import { paramGetModel } from './param'
import { arrayConvert } from './param-convert'

const getElementsBelow = (graph, cell) => {
  const elementsBelow = graph.findModelsUnderElement(cell)
  const sortedElementsBelow = _.sortBy(elementsBelow, element => - element.get('z'))
  return sortedElementsBelow.filter(el => el.id != cell.id)
}

const getElementBelow = (graph, cell) => {
  const elementsBelow = getElementsBelow(graph, cell)
  if (elementsBelow.length) {
    return elementsBelow[0]
  }
}

export const embeddingLinkResolve = link => {
  const inboundEmbedding = link.get('inboundEmbedding')
  if (inboundEmbedding) { 
    var sourceElement = link.getSourceElement()
    var targetElement = link.getTargetElement()
    }
  else {
    var sourceElement = link.getTargetElement()
    var targetElement = link.getSourceElement()
  }
  
  const sourceParentId = sourceElement.parent()
  const targetParentId = targetElement.parent()
  if (sourceParentId && sourceParentId === targetParentId && link.parent() !== sourceParentId) {
    if (link.parent()) {
      link.getParentElement().unembed(link)
    }
    const parent = sourceElement.getParentCell()
    parent.embed(link)
  } else if (link.parent()) {
    link.getParentCell().unembed(link)
  }
}

export const elementConnectedLinksEmbeddingResolve = (graph, element) => {
  graph.getConnectedLinks(element).forEach(embeddingLinkResolve)
}

const embedElement = (context, position, element) => {
  const elementBelow = getElementBelow(context.graph, element)

  // Prevent recursive embedding.
  if (elementBelow && elementBelow !== element && elementBelow.get('parent') !== element.id) {
    const allowEmbedding = allowElementEmbedding(context, elementBelow)
    if (allowEmbedding) {
      callAsBatch(context, async () => {
        // There may be multiple links in both directions that can be embedded
        const inboundLinks = context.graph.getConnectedLinks(element, { inbound: true })
        const embeddableInboundLinks = inboundLinks.filter(link => {
          const linkType = link.get('type');
          if (context.shapeTypeModelMap[linkType].embeddable && (!context.shapeTypeModelMap[linkType].inboundEmbedding) && link.getSourceElement().id == elementBelow.id)
            return true
          })

        const outboundLinks = context.graph.getConnectedLinks(element, { outbound: true })
        const embeddableOutboundLinks = outboundLinks.filter(link => {
          const linkType = link.get('type');
          if (context.shapeTypeModelMap[linkType].embeddable && context.shapeTypeModelMap[linkType].inboundEmbedding && link.getTargetElement().id == elementBelow.id)
            return true
        })
        const embeddableLinks = embeddableInboundLinks.concat(embeddableOutboundLinks)

        if (embeddableLinks.length) {
          elementBelow.embed(element)
          var embeddedLinks = []
          embeddableLinks.forEach(embeddableLink => {
            embeddedLinks.push({type: embeddableLink.get('type'), id: embeddableLink.get('id')})
            embeddableLink.remove()
          })
          element.set('embeddedLink', arrayConvert(embeddedLinks))
          elementConnectedLinksEmbeddingResolve(context.graph, element)
        } else {
           await newEmbeddingResolve(context, element, elementBelow, position)
        }
      })
    }
  }
}

const getEmbeddedElements = cell => {
  const embeddedCells = cell.getEmbeddedCells()
  return embeddedCells.filter(cell => !cell.isLink())
}

const setParentSize = parent => {

  //if (!parent.get('originalPosition')) parent.set('originalPosition', parent.get('position'))
  //if (!parent.get('originalSize')) parent.set('originalSize', parent.get('size'))

  const originalPosition = parent.get('position')
  const originalSize = parent.get('size')

  let newX = originalPosition.x
  let newY = originalPosition.y
  let newCornerX = originalPosition.x + originalSize.width
  let newCornerY = originalPosition.y + originalSize.height

  const embeddedElements = getEmbeddedElements(parent)

  embeddedElements.forEach(child => {
    const childBbox = child.getBBox()

    if (childBbox.x < newX) {
      newX = childBbox.x
    }
    if (childBbox.y < newY) {
      newY = childBbox.y
    }
    if (childBbox.corner().x > newCornerX) {
      newCornerX = childBbox.corner().x
    }
    if (childBbox.corner().y > newCornerY) {
      newCornerY = childBbox.corner().y
    }
  })

  // Note that we also pass a flag so that we know we shouldn't adjust the
  // `originalPosition` and `originalSize` in our handlers as a reaction
  // on the following `set()` call.
  parent.set({
    position: {
      x: newX,
      y: newY
    },
    size: {
      width: newCornerX - newX,
      height: newCornerY - newY
    }
  })
}


const unembedElement = (context, parent, element) => {
  parent.unembed(element)
  elementConnectedLinksEmbeddingResolve(context.graph, element)
  
  const embeddedLinks = arrayConvert(element.get('embeddedLink'))
  if (embeddedLinks) {
    element.set('embeddedLink', [])
    embeddedLinks.forEach(embeddedLink => {
      const shape = jointGetShape(embeddedLink.type)
      const parentId = parent.id
      const childId = element.id

      const inboundEmbedding = context.shapeTypeModelMap[embeddedLink.type].inboundEmbedding

      if (inboundEmbedding) { 
        var sourceId = childId
        var targetId = parentId
      }
      else {
        var sourceId = parentId
        var targetId = childId
      }
      const link = new shape({id: embeddedLink.id})
      link.set('source', {
        id: sourceId,
        selector: '.link-target'
      })
      link.set('target', {
        id: targetId,
        selector: '.link-target'
      })
      //link.set('embeddable', true)
      context.graph.addCell(link)
   })
  }
}

const allowElementEmbedding = (context, element) => {
  const shapes = context.paramShapes
  const shape = paramShapesGetCellShape(shapes, element)
  return paramShapeGetAllowEmbedding(shape)
}

export const resolveEmbedding = (context, element) => {
  const parentId = element.get('parent')
  const elementBelow = getElementBelow(context.graph, element)
  var elementBelowId;
  if (elementBelow)
    elementBelowId = elementBelow.id;
  if (parentId != elementBelowId) {
    if (parentId) {
      const parent = context.graph.getCell(parentId)
      if (!parent.getBBox().intersect(element.getBBox())) {
        // Vynořujeme
        unembedElement(context, parent, element)
      } else {
        setParentSize(parent)
      }
    }
    // Can embed and unembedd at the same time (when moved from one parent to another)
    const position = {x: event.pageX, y: event.pageY}
    embedElement(context, position, element)
  }
}

const newEmbeddingResolve = async (context, element, elementBelow, position) => {
  const param = contextGetParam(context)
  const model = paramGetModel(param)
  const sourceElementName = context.shapeTypeModelMap[elementBelow.get('type')].name
  const targetElementName = context.shapeTypeModelMap[element.get('type')].name
  const ends = {
    source: {
      elementName: sourceElementName
    },
    target: {
      elementName: targetElementName
    }
  }
  const possibleLinks = paramModelGetPossibleLinks(model, ends)
  const embeddableSourceTargetPossibleLinks = possibleLinks.filter(paramLinkGetEmbeddable)
  const embeddableOutboundPossibleLinks = embeddableSourceTargetPossibleLinks.filter(paramLinkGetOutboundEmbeddable)


  const reverseEnds = {
    source: {
      elementName: targetElementName
    },
    target: {
      elementName: sourceElementName
    }
  }

  const possibleReverseLinks = paramModelGetPossibleLinks(model, reverseEnds)
  const embeddableTargetSourcePossibleLinks = possibleReverseLinks.filter(paramLinkGetEmbeddable)
  const embeddableInboundPossibleLinks = embeddableTargetSourcePossibleLinks.filter(paramLinkGetInboundEmbeddable)


  const embeddablePossibleLinks = embeddableOutboundPossibleLinks.concat(embeddableInboundPossibleLinks)

  if (embeddablePossibleLinks.length !== 0) {
    const items = embeddablePossibleLinks.map(link => {
      return {title: paramModelLinkGetTitle(link), value: link}
    })
    const link = await displayContextMenu(items, context.paper.$el, position)
    if (link) {
      elementBelow.embed(element)
      const shape = paramModelLinkGetShapes(link)[0]
      element.set('embeddedLink', {type: shape.type, id: joint.util.uuid()})
    }
    elementConnectedLinksEmbeddingResolve(context.graph, element)
  }
}

export const embeddingAfterAddResolve = (context, position, element) => {
  const elementBelow = getElementBelow(context.graph, element)
  if (elementBelow) {
    const allowEmbedding = allowElementEmbedding(context, elementBelow)
    if (allowEmbedding) {
      callAsBatch(context, async () => {
        await newEmbeddingResolve(context, element, elementBelow, position)
      })
    }
  }
}