import { jointGetShape } from './joint-shape'
import { contextGetParam, callWithoutChange } from './context.js'
import { paramGraphGetElement, paramGraphUpdateElement } from './param-graph'
import { clone } from './library'
import { paramGetGraph } from './param'



export const updateCellVisual = (graph, element, type) => {
  const shape = jointGetShape(type)
  // cannot rewrite all attrs as the definitions may completely differ. Partial rewriting of attrs to preserve line and fill styles woudl be nice ...
  const textProperty = element.get('textProperty')
  const newElement = new shape({id: element.get('id'), position: element.get('position') /*, attrs: element.get('attrs')*/})
  const links = graph.getConnectedLinks(element)
  if (textProperty) {
    const elementLabel = element.attr(textProperty)
    if (elementLabel) {
      newElement.attr(textProperty, elementLabel)
    }
  }
  element.remove()
  graph.addCell(newElement)
  if (links.length > 0) {
    graph.addCells(links)
  }
  return newElement
}

export const switchCellVisual = (context, element, type) => {
  const graph = context.graph
  //context.commandManager.initBatchCommand()
  // type canot by changed 
  callWithoutChange(context, () => {
    updateCellVisual(graph, element, type)
    const param = contextGetParam(context)
    const parahGraph = paramGetGraph(param)
    const paramElement = paramGraphGetElement(parahGraph, element.get('id'))
    const newParamElement = {...paramElement, type}
    param.graph = paramGraphUpdateElement(parahGraph, newParamElement)
    context.displayedParamGraph = clone(param.diagram)
    param.change = [{action: 'change:type'}]
    MC.handleEvent(context.props.data, 'change')
  })
  
  //context.commandManager.storeBatchCommand()
}