import {Modal} from './modal.js'
import $ from 'jquery'

export const ContextMenu = Modal.extend({
  className: 'context-menu',
  eventNamespace: 'context-menu',
  events: { 'click .item': 'onItemPointerdown' },
  render: function() {
    if (this.options.type) {
      this.$el.attr('data-type', this.options.type)
    }
    Modal.prototype.render.apply(this, arguments)
  },
  renderContent: function() {
    const $items = $('<div/>', { 'class': 'ui vertical menu' })
    if (this.options.items) {
      _.each(this.options.items, function(item) {
        let $html
        if (item.icon) {
          $html = $('<img/>', { src: item.icon })
        } else {
          $html = item.content
        }
        const $item = $('<a/>', {
            'class': 'item',
            html: $html,
            'data-action': item.action
        })
        if (item.attrs) {
            $item.attr(item.attrs)
        }
        $items.append($item)
      })
    }
    this.$el.append($items)
  },
  onRemove: function() {
    Modal.prototype.onRemove.apply(this, arguments)
    this.trigger('context-menu:remove')
  },
  onItemPointerdown: function(event) {
    this.remove()
    const $item = $(event.target).closest('[data-action]')
    const action = $item.attr('data-action')
    if (action) {
        this.trigger('action:' + action, event)
    }
  }
})

export const displayContextMenu = (items, $el, position) => {
    return new Promise(resolve => {
      let contextMenuItems = items.map((item, i) => {
        return {
          action: String(i),
          content: item.title,
          callback:  () => {
              resolve(item.value)
          }
        }
      })
      //contextMenuItems = _.sortBy(contextMenuItems, ['content'])
    
      const contextMenu = new ContextMenu({
        items: contextMenuItems,
        target: $el,
        position
      })
    
      contextMenuItems.forEach(item => {
        contextMenu.on('action:' + item.action, item.callback)
      })
      contextMenu.on('modal:canceled', () => resolve())
      contextMenu.render()
    })
  }